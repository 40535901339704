const alb06 = {
    "name" : "alb06",
    "artists" : "",
    "title" : "Brahms et Mendelssohn",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1108",
            "artists" : ["Sarah Nemtanu, violon *","Guillaume Chilemme, violon **","Liya Petrova, violon *","David Petrlik, violon","Nathan Mierdl, violon *",
            "Lise Berthaud, alto","Adrien La Marca, alto","Ophélie Gaillard, violoncelle","Christian-Pierre La Marca, violoncelle","-","* Octuor uniquement , ** Sextuor uniquement"],
            "duration" : "1:25:00",
            "description" : "",
            "sections" : [
                {
                    "name" : "Johannes Brahms (1833-1897), Sextuor à cordes n° 1 en sib majeur, op. 18",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Allegro, ma non troppo",
                            "duration" : "13:10"
                        },
                        {
                            "number" : "2",
                            "title" : "Andante, ma moderato",
                            "duration" : "8:22"
                        },
                        {
                            "number" : "3",
                            "title" : "Scherzo. Allegro molto",
                            "duration" : "2:49"
                        },
                        {
                            "number" : "4",
                            "title" : "Rondo. Poco Allegretto e grazioso",
                            "duration" : "9:15"
                        }
                    ]
                },
                {
                    "name" : "Félix Mendelssohn (1809-1847), Octuor à cordes en mib majeur, op. 20",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "5",
                            "title" : "Allegro moderato con fuoco",
                            "duration" : "13:27"
                        },
                        {
                            "number" : "6",
                            "title" : "Andante",
                            "duration" : "6:08"
                        },
                        {
                            "number" : "7",
                            "title" : "Scherzo. Allegro leggierissimo",
                            "duration" : "4:24"
                        },
                        {
                            "number" : "8",
                            "title" : "Presto",
                            "duration" : "6:06"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb06
const alb03 = {
    "name" : "alb03",
    "artists" : "",
    "title" : "Festival de Pâques d’Aix-en-Provence",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1102",
            "artists" : ["Renaud Capuçon, violon  (avec l'aimable autorisation de Warner Classics)",
            "Kian Soltani, violoncelle  (avec l'aimable autorisation de Deutsche Grammophon)",
            "Lahav Shani, piano"],
            "description" : "Concert enregistré le 5.04.18, Conservatoire Darius Milhaud",
            "duration" : "1:25:00",
            "sections" : [
                {
                    "name" : "Antonín Dvořák (1841-1904), Trio n° 3 en fa mineur, op. 65",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Allegro ma non troppo",
                            "duration" : "12:55"
                        },
                        {
                            "number" : "2",
                            "title" : "Allegretto grazioso, meno mosso",
                            "duration" : "6:27"
                        },
                        {
                            "number" : "3",
                            "title" : "Poco adagio",
                            "duration" : "9:33"
                        },
                        {
                            "number" : "4",
                            "title" : "Finale. Allegro con brio",
                            "duration" : "9:26"
                        }
                    ]
                },
                {
                    "name" : "Piotr Ilitch Tchaïkovski (1840-1893), Trio pour piano en la mineur, op. 50",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "5",
                            "title" : "Pezzo elegiaco",
                            "duration" : "17:27"
                        },
                        {
                            "number" : "6",
                            "title" : "Tema con variazione",
                            "duration" : "23:10"
                        }
                    ]
                }
            ]
        },
        {
            "name" : "CD2",
            "media" : "LT1103",
            "artists" : ["Veronika Eberle, violon","Deutsche Kammerphilharmonie Bremen","Paavo Järvi, direction"],
            "duration" : "1:25:00",
            "compositeur" : "Johannes Brahms (1833-1897)",
            "description" : "Concert enregistré le 6.04.18, Grand Théâtre de Provence",
            "sections" : [
                {
                    "name" : "Concerto pour violon en ré majeur, op. 77",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Allegro non troppo",
                            "duration" : "22:51"
                        },
                        {
                            "number" : "2",
                            "title" : "Adagio",
                            "duration" : "8:21"
                        },
                        {
                            "number" : "3",
                            "title" : "Allegro giocoso, ma non troppo vivace",
                            "duration" : "8:06"
                        }
                    ]
                },
                {
                    "name" : "Symphonie n° 1 en ut mineur, op. 68",
                    
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "4",
                            "title" : "Un poco sostenuto. Allegro",
                            "duration" : "12:47"
                        },
                        {
                            "number" : "5",
                            "title" : "Andante sostenuto",
                            "duration" : "8:06"
                        },
                        {
                            "number" : "6",
                            "title" : "Un poco allegretto e grazioso",
                            "duration" : "4:35"
                        },
                        {
                            "number" : "7",
                            "title" : "Adagio. Piu andante. Allegro non troppo, ma con brio.",
                            "duration" : "16:54"
                        }
                    ]
                }
            ]
        },
        {
            "name" : "CD3",
            "media" : "LT1104",
            "artists" : ["Lucas Debargue, piano (avec l'aimable autorisation de Sony)","Orchestre national de Russie","Mikhail Pletnev, direction"],
            "duration" : "1:25:00",
            "description" : "Concert enregistré le 7.04.18, Grand Théâtre de Provence",
            "sections" : [
                {
                    "name" : "Maurice Ravel (1875-1937), Concerto pour piano en sol majeur",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Allegramente",
                            "duration" : "9:22"
                        },
                        {
                            "number" : "2",
                            "title" : "Adagio assai",
                            "duration" : "9:36"
                        },
                        {
                            "number" : "3",
                            "title" : "Presto",
                            "duration" : "4:45"
                        }
                    ]
                },
                {
                    "name" : "Piotr Ilitch Tchaïkovski (1840-1893), Symphonie n° 3 \"Polonaise\" en ré majeur, op. 29",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "4",
                            "title" : "Introduzione e allegro",
                            "duration" : "15:52"
                        },
                        {
                            "number" : "5",
                            "title" : "Alla tedesca",
                            "duration" : "6:37"
                        },
                        {
                            "number" : "6",
                            "title" : "Andante",
                            "duration" : "9:58"
                        },
                        {
                            "number" : "7",
                            "title" : "Scherzo",
                            "duration" : "6:12"
                        },
                        {
                            "number" : "8",
                            "title" : "Finale",
                            "duration" : "9:05"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb03
const alb01 = {
    "name" : "alb01",
    "artists" : "",
    "title" : "La Folle Journée de Nantes",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1105",
            "artists" : ["Luis Fernando Pérez, piano","Orchestre philharmonique de l'Oural","Dmitri Liss, direction"],
            "duration" : "1:25:00",
            "compositeur" : "Sergueï Rachmaninoff (1873-1943)",
            "description" : "Concert enregistré le 2.02.18, Auditorium Stefan Zweig",
            "sections" : [
                {
                    "name" : "",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Rhapsodie sur un thème de Paganini, op. 43",
                            "duration" : "24:03"
                        }
                    ]
                },
                {
                    "name" : "Danses symphoniques, op. 45",
                    "duration" : "11:47",
                    "tracks" : [
                        {
                            "number" : "2",
                            "title" : "Non allegro",
                            "duration" : "11:47"
                        },
                        {
                            "number" : "3",
                            "title" : "Andante con moto. Tempo di Valse",
                            "duration" : "9:57"
                        },
                        {
                            "number" : "4",
                            "title" : "Lento assai. Allegro vivace",
                            "duration" : "13:10"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb01
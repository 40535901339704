import React from 'react'
import { Navbar, NavbarBrand, NavbarNav,  NavLink } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

const names = {
    cic : 'CIC',
    cicbp : 'CIC Banque Privée',
    cmcicinvest : 'CM-CIC Invesstissement',
    cmcicms : 'CM-CIC Market Solutions',
    cmcicconseil : 'CM-CIC Conseil',
    cmcices : 'CM-CIC Epargne Salariale',
    cmcicam : 'CM-CIC Asset Management',
    cmcicbail : 'CM-CIC Bail',
    becm : 'Banque Européenne - Crédit Mutuel'
  }

const Navigation = (props) => {

    var domain = window.location.href.match(/collection([a-zA-Z]+)2018/i)
    var m=domain?domain[1]:'cic'

return(
<Router>
    <Navbar style={{backgroundColor:'#111111'}} dark expand="md" scrolling>
        <NavbarBrand >
            Collection {names[m]} 2018
        </NavbarBrand>
        <NavbarNav right>
            <NavLink to="#" onClick={()=>{
                if(props.onHelpClick)
                    props.onHelpClick()
            }}>
            J'ai besoin d'assistance
            </NavLink>
            {/* <NavLink to="#" onClick={()=>{
                localStorage.removeItem('code')
                window.location.reload()
            }}>Se déconnecter</NavLink> */}
        </NavbarNav>
    </Navbar>
</Router>    
)
}

export default Navigation
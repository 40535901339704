import React  from 'react'
import {Button, Input,Row,Col} from 'mdbreact'

import {CSVLink} from 'react-csv'
import {getCodes,getCode,getActiveCodes,resetCode,saveCaddy,createCaddy} from '../firebase/manager'
 
import alb01 from '../Medias/alb01.js'
import alb02 from '../Medias/alb02.js'
import alb03 from '../Medias/alb03.js'
import alb04 from '../Medias/alb04.js'
import alb05 from '../Medias/alb05.js'
import alb06 from '../Medias/alb06.js'
import alb07 from '../Medias/alb07.js'

const baseUrl =  'https://us-central1-collectioncic2018.cloudfunctions.net/'


class Admin extends React.Component  {

    constructor(props)
    {
        super(props)
        console.log(props)
        var token = props.match.params.token

        this.state={token, prepareCsv:true,prepareActiveCsv:true,downloadCsv:false}
        this.handlePrepare=this.handlePrepare.bind(this)
        this.handleReinit=this.handleReinit.bind(this)
        this.handleGetCode = this.handleGetCode.bind(this)
    }

    handleReinit(code)
    {
        resetCode(code).then(()=>{
            alert('code :' + code + ' rechargé')
        })
    }

    handlePrepare(active)
    {
        console.log({active})
        if(active===0)
        {
            this.setState({...this.state,prepareCsv:false,downloadCsv:false,prepareActiveCsv:false,downloadActiveCsv:false,data:[]},()=>{
                getCodes().then((data)=>{
                    this.setState({...this.state,prepareCsv:false,downloadCsv:true,prepareActiveCsv:false,downloadActiveCsv:false,data:data})
                })
            })
        }
        else
        {
            this.setState({...this.state,prepareCsv:false,downloadCsv:false,prepareActiveCsv:false,downloadActiveCsv:false,data:[]},()=>{            
                getActiveCodes().then((data)=>{
                    this.setState({...this.state,prepareActiveCsv:false,downloadActiveCsv:true,data:data})
                })
            })
        }

    }

    createCaddy(alb)
    {
        var title = alb.title
        var caddies=[]

        alb.disks.forEach((disk)=>{
            var caddy={caddyId:disk.media, DeliveryItems:[],CaddyStatusID:0}
            disk.sections.forEach((section)=>{
                section.tracks.forEach((track)=>{
                    var DeliveryItem={}
                    DeliveryItem.ItemID=disk.media + '-' + track.number
                    DeliveryItem.DiskNumber=1
                    DeliveryItem.TrackOrder=track.number
                    DeliveryItem.CleanAlbum=title + " - " + disk.name
                    DeliveryItem.CleanTrack=track.title
                    DeliveryItem.StatusID=3
                    caddy.DeliveryItems.push(DeliveryItem)
                })
            })
            caddies.push(caddy)        
        })
        return caddies
    }

    handleCreateCaddies()
    {
//        var caddies=[alb01,alb02,alb03,alb04,alb05,alb06,alb07]
        var caddies=[alb06]
        caddies.forEach((ca)=>{
            console.log(ca.title)
            this.createCaddy(ca).forEach((caddy)=>{
                var name = caddy.caddyId
                var data = JSON.stringify(caddy)
                saveCaddy(name,data).then((res)=>{
                    console.log(res)
                })
            })
        })
    }

    handleCreateCaddy()
    {
        var name = 'LT1101'
        var code = localStorage.getItem('code')
        createCaddy(name,code).then((caddy)=>{
            console.log(caddy)
        })
    }

    handleGetCode(e)
    {
        e.preventDefault()
        getCode(this.state.getCode).then((data)=>{
            var codeData=data
            this.setState({...this.state,showCode:true,codeData})
        })

    }

    render()
    {
        return(
            <div className="App bgWhite">
                <header className="App-header">
                <h1 className="App-title">Collection CIC 2018</h1>
                </header>
                <div className="container">
                  <div className="row justify-content-md-center">
                    <div className="col-12 col-md-auto my-5">
                      <h3>{(this.state.token==="099")?"Administration":"Accès Interdit"}</h3>
                    </div>
                  </div>
                  <hr/>
                  {
                      this.state.token==="099" &&
                        <div>                        
                        <Row>
                            <Col>
                            <div className="col-12 col-md-auto my-1">
                                Téléchargement du fichier des Codes
                            </div>
                            <div className="col-6 col-md-auto my-1">
                                    {this.state.prepareCsv && <Button onClick={()=>{this.handlePrepare(0)}}>Préparer le Fichier</Button> }
                                    {this.state.downloadCsv && <CSVLink filename={"codes.csv"} data={this.state.data}>Télécharger le fichier</CSVLink>}
                            </div>
                            </Col>
                            <Col>
                            <div className="col-6 col-md-auto my-1">
                                     <Button onClick={()=>{this.handleReinit(this.state.resetCode)}} >Réinitialiser un code</Button>
                                     <Input onChange={(e)=>{this.setState({...this.state,resetCode:e.target.value})}} label="Entrez le code" />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col><div className="col-12 col-md-auto my-1">
                                Téléchargement du fichier des Codes Actifs
                            </div>
                            <div className="col-6 col-md-auto my-1">
                                    {this.state.prepareActiveCsv && <Button onClick={()=>{this.handlePrepare(1)}}>Préparer le Fichier</Button> }
                                    {this.state.downloadActiveCsv && <CSVLink filename={"codesActifs.csv"} data={this.state.data}>Télécharger le fichier</CSVLink>}
                            </div></Col>
                            <Col>
                            <div className="col-6 col-md-auto my-1">
                                     <Button onClick={(e)=>{this.handleGetCode(e)}} >Consulter l'activité du code</Button>
                                     <Input onChange={(e)=>{this.setState({...this.state,getCode:e.target.value})}} label="Entrez le code" />
                            </div>
                            </Col>                            
                        </Row>
                        <hr/>
                        {this.state.showCode &&
                        <Row>

                            <Col>
                                <Row>
                                    code : <b>{this.state.getCode}</b>
                                </Row>
                                <Row>
                                    nombre d'accès : <b>{this.state.codeData.access}</b>
                                </Row>
                                <Row >
                                    dernier accès : <span style={{color:'blue',fontStyle:'italic'}} >{new Date(this.state.codeData.lastAccess).toLocaleString('fr-Fr')}</span>
                                </Row>
                                <Row>installations restantes : <b>{this.state.codeData.usages}</b></Row>
                            </Col>
                            <Col>
                                {
                                    this.state.codeData.logUsages && 
                                    this.state.codeData.logUsages.map((v,i)=>{
                                        return(
                                            <Row>
                                                <Col style={{color:'blue',fontStyle:'italic'}} >{new Date(v.dt).toLocaleString('fr-Fr')}</Col>
                                                <Col style={{fontSize:'0.7em'}} >{v.ua}</Col>
                                            </Row>
                                        )

                                    })
                                }
                            </Col>
                        <hr/>
                        </Row>                        
                        
                        }

                        
                        
                        <div className="col-12 col-md-auto mt-4 mb-1">
                            Statistiques d'utilisation
                        </div>
                        
                        <Button onClick={()=>{
                            this.handleCreateCaddies()
                        }}>Create Caddy Templates</Button>
                        
                        <Button onClick={()=>{
                            this.handleCreateCaddy()
                        }}>Create a Caddy</Button>
                        <a href="cicdownload://download/0104017562/LT1101">télécharger le caddy</a>
                        
                        <hr/>

                        <a href="https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com/o/DownloaderCic-2.6.2.dmg?alt=media&token=6f910476-3679-4b66-ac48-8aa8760713d5">
                        <h3>Télécharger le gestionnaire de téléchargement</h3>
                        </a>
                        
                        </div>

                        
                  }
                  

              </div>
            </div>
            )
    }

}


export default Admin
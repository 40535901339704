import React from 'react';
import { Container, Collapse, Button, Modal, ModalBody, ModalHeader, ModalFooter, Input } from 'mdbreact';
//import mailgun from 'mailgun-js'

import {addSupport} from '../firebase/manager'

import platform from 'platform'

import {saveAction} from '../webservice/index'

class HelpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSend:true,
      showText:true,
      visible: props.visible,
      sent:false,
      email:'',
      message:'',
      page:props.page,
      sender:props.sender
    };
    this.onChange=this.onChange.bind(this)
  }


  toggle() {
    this.setState({ ...this.state,
      visible: !this.state.visible
    });
  }

  onChange(e)
  {
    console.log(this.state.showText)
    if(this.state.showText)
    {
      this.setState({showText:false})
    }
    if(this.state.disableSend)
      this.setState({disableSend:false})
    if(e.target.name==='email')
    {
        this.setState({email:e.target.value})
    }
    if(e.target.name==='message')
    {
        this.setState({message:e.target.value})
    }
  }

  onValidate()
  {
        var infos = platform.ua
        var code = localStorage.getItem('code')
        this.setState({disableSend:true})
        console.log({email:this.state.email,message:this.state.message,infos})
        addSupport(this.state.email,code,infos,this.state.message,this.state.sender).then(()=>{
          saveAction('help sent')
          console.log('message done')
          if(this.props.onClose)
            this.props.onClose()
        })
  }

  render() {

    return (
      <Container>
        <Modal isOpen={this.props.visible} toggle={this.props.onClose} fullHeight position="center">
          <ModalHeader toggle={this.props.onClose}>Demande d'assistance</ModalHeader>
          <ModalBody>
            <Collapse isOpen={this.state.showText}>
            <p>Vous rencontrez un problème pour valider votre code ?</p>
            <p>Vous avez un souci pour télécharger ou écouter la musique ?</p>
            <p>Vous pouvez obtenir une assistance en remplissant ce formulaire, nous ferons notre possible pour revenir vers vous dans les meilleurs délais.</p>
            </Collapse>
            <form>
                <Input name="email" label="Entrez votre email" icon="envelope" onChange={this.onChange} />
                <Input name="message" type="textarea" rows="4" label="Décrivez sommairement votre problème (200 caractères max.)" onChange={this.onChange} icon="pencil"/>
            </form>
            <Collapse isOpen={this.state.sent}>
            </Collapse>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.onClose}>Annuler</Button>
            <Button color="primary" disabled={this.state.disableSend} onClick={()=>this.onValidate()} >Envoyer</Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default HelpModal
const alb07 = {
    "name" : "alb07",
    "artists" : "",
    "title" : "Mendelssohn",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1109",
            "artists" : ["Trio Metral","Joseph Metral, violon","Justine Metral, violoncelle","Victor Metral, piano"],
            "duration" : "1:25:00",
            "compositeur" : "Félix Mendelssohn (1809-1847)",
            "sections" : [
                {
                    "name" : "Trio pour piano et cordes n°1 en ré mineur, op. 49",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Molto allegro agitato",
                            "duration" : "9:58"
                        },
                        {
                            "number" : "2",
                            "title" : "Andante con moto tranquillo",
                            "duration" : "6:34"
                        },
                        {
                            "number" : "3",
                            "title" : "Scherzo. Leggiero e vivace",
                            "duration" : "3:51"
                        },
                        {
                            "number" : "4",
                            "title" : "Finale. Allegro assai appassionato",
                            "duration" : "8:57"
                        }
                    ]
                },
                {
                    "name" : "Trio pour piano et cordes n°2 en ut mineur, op. 66",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "5",
                            "title" : "Allegro energico e con fuoco",
                            "duration" : "10:13"
                        },
                        {
                            "number" : "6",
                            "title" : "Andante espressivo",
                            "duration" : "6:15"
                        },
                        {
                            "number" : "7",
                            "title" : "Scherzo. Molto allegro, quasi presto",
                            "duration" : "3:36"
                        },
                        {
                            "number" : "8",
                            "title" : "Finale. Allegro appassionato",
                            "duration" : "7:44"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb07
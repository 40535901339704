import alb01 from './alb01.js'
import alb02 from './alb02.js'
import alb03 from './alb03.js'
import alb04 from './alb04.js'
import alb05 from './alb05.js'
import alb06 from './alb06.js'
import alb07 from './alb07.js'
import visuel1 from '../Images/Boccherini.png'
import visuel2 from '../Images/Festival Aix.png'
import visuel3 from '../Images/Folle jour.png'
import visuel4 from '../Images/Victoires.png'
import visuel5 from '../Images/Edward Elgar.png'
import visuel6 from '../Images/Brahms.png'
import visuel7 from '../Images/Mendelssohn.png'
import bvisuel1 from '../altdigital/covers-becm-boccherini.png'
import bvisuel2 from '../altdigital/covers-becm-aix.png'
import bvisuel3 from '../altdigital/covers-becm-nantes.png'
import bvisuel4 from '../altdigital/covers-becm-victoires.png'
import bvisuel5 from '../altdigital/covers-becm-elgar.png'
import bvisuel6 from '../altdigital/covers-becm-brahms.png'
import bvisuel7 from '../altdigital/covers-becm-mendelssohn.png'

export const concerts = (becm)=>{
    return ([
        {
            title: alb02.title,
            artists: alb02.artists,
            visuel:becm?bvisuel1:visuel1,
            id: "boccherini"
        },
        {
            title: alb03.title,
            artists: alb03.artists,
            visuel:becm?bvisuel2:visuel2,
            id: "festivalaix"
        },{
            title: alb01.title,
            artists: alb01.artists,
            visuel:becm?bvisuel3:visuel3,
            id: "jour"
        },{
            title: alb04.title,
            artists: alb04.artists,
            visuel:becm?bvisuel4:visuel4,
            id: "victoire"
        },{
            title: alb05.title,
            artists: alb05.artists,
            visuel:becm?bvisuel5:visuel5,
            id: "edward"
        },{
            title: alb06.title,
            artists: alb06.artists,
            visuel:becm?bvisuel6:visuel6,
            id: "brahms"
        },{
            title: alb07.title,
            artists: alb07.artists,
            visuel:becm?bvisuel7:visuel7,
            id: "mendel"
        },
    ])
}

import React , {Component} from 'react'
import { Fa } from 'mdbreact';


class Section extends Component
{
    constructor(props)
    {
        super(props)
        this.section = this.props.section
    }

    render()
    {
        var section = this.section
        
        return (
            section.name?
            <div>
                {section.bonus?
                    <div className="row blue-text">
                        <div className="col-9">
                        <strong>{section.name}</strong>
                        <hr className="mt-0"/>
                        </div>
                        <div className="col-3"><Fa icon="star" className="mr-2"/>bonus digital</div>
                        
                    </div>
                :
                    <div className="row">
                        <div className="col-11">
                        {section.name}
                        <hr className="mt-0"/>
                        </div>
                        <div className="col-1"></div>
                        
                    </div>
                }
            </div>
            :
            ""
        )
    }

}

export default Section
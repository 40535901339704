import React from 'react'
import PropTypes from 'prop-types'

import windwn from '../Images/win-dwn-progress.png'
import winccu from '../Images/win-ccu.png'
import confirmprotocol2 from '../Images/firefoxconfirm2.png'


import {Button, Fa , Row, Col} from 'mdbreact';

const InstallWin = ({showWaitingDownloader,onClickValidate,code,device}) => {
    return (
        <div>
                <h3 className="textCenter mb-5 mt-0 pt-5">Installation du gestionnaire Windows</h3>
                <p>Le téléchargement du gestionnaire est en cours. Vous pouvez suivre sa progression dans votre naviguateur.</p>
                <Row>
                    <Col md="2">
                    <h1>1.</h1>
                    </Col><Col md="4">
                        <img src={windwn} width="200" alt=""/>
                        <p>Lorsque le téléchargement sera terminé, exécutez le fichier téléchargé.</p>
                    </Col>
                    <Col md="2">
                    <h1>2.</h1>
                    </Col><Col md="4">
                    <p>Windows vous demande alors de confirmer votre action.</p>
                        <img src={winccu} width="100%" alt="" />
                    </Col>
                </Row>
                
                <Row className="mt-3">
                    
                    <Col md="2">
                    <h1>3.</h1>
                    </Col>
                    <Col md="4">
                    <p className="mt-5">Cliquez sur ce bouton pour valider l'installation.</p>
                    <Button className="btn-warning mt-1" disabled={showWaitingDownloader} onClick={onClickValidate}
                     tag="a" color='warning' href={"coldownload://validate/" + code + "/" + device}>
                            Valider l'installation                      
                        </Button>
                    </Col>
                    <Col md="2">
                    <h1>4.</h1>
                    </Col><Col md="4">
                    <p className="mt-3">Lorsque vous cliquez sur la validation votre navigateur ouvre 
                    une fenêtre pour valider l'association avec le gestionnaire.</p>
                        <img src={confirmprotocol2} width="100%" alt="" />
                        <p className="mt-3">Cochez bien la case "Se souvenir de mon choix" puis cliquez sur le bouton "Ouvrir le lien".</p>
                    </Col>
                </Row>          
              
                {showWaitingDownloader &&
                <Row>
                    <h1>Détection de l'ouverture du gestionnaire ...</h1>
                </Row>}
        </div>
    )
}

InstallWin.propTypes = {
    showWaitingDownloader : PropTypes.bool.isRequired,
    onClickValidate: PropTypes.func.isRequired,
    code : PropTypes.string.isRequired,
    device : PropTypes.string.isRequired
}

export default InstallWin;
import React from 'react';
import { Input, Button, Collapse,Fa } from 'mdbreact';

import {checkCode,validateCode} from '../firebase/manager'

class GetCode extends React.Component  {
    constructor(props)
    {
        super(props)
        console.log(props);

            this.state={code:'',showButton:true,showError:false}
        
        this.handleClick=this.handleClick.bind(this)
        this.handleHelp=this.handleHelp.bind(this)
    }

    handleHelp()
    {
        if(this.props.onHelp)
            this.props.onHelp()
    }

    handleClick(e)
    {
        
        if(e)
            e.preventDefault()
        var code=this.state.code
        console.log(code);
        if(!localStorage.getItem('code'))
        {
            this.setState({...this.state,showButton:false,showError:false})
            validateCode(code).then((u)=>{
                console.log(u)
                if(u.usages && u.usages>0)
                {
                    localStorage.setItem('code',code)
                    window.location.replace("/Media/"+code)
                }
                else
                {
                    this.setState({...this.state,showButton:false,showError:true},
                        ()=>{
                        if(this.props.onCodeFailure)
                            this.props.onCodeFailure()    
                        setTimeout(()=>{
                        this.setState({...this.state,code:'',showButton:true,showError:false})},3000)})
                }
            })
        }
        else
        {
            checkCode(code).then((u)=>{
                console.log(u);
                if(u.access && u.access>=0)
                    window.location.replace("/Media/"+code)
                else
                {
                    this.setState({...this.state,showButton:false,showError:true},
                        ()=>{setTimeout(()=>{
                        this.setState({...this.state,code:'',showButton:true,showError:false})},3000)})
                }
            })
        }     
        //       
    }

    render() {
      return(
            <div style={{width:300}}>
            <p className="h4 white-text text-center mb-0 mt-3">Saisissez votre code</p>
                
                    <div className="white-text">
                    <Input className="white-text" label="Tapez votre code" name="code" group type="text"
                    onChange={(e)=>{
                        e.preventDefault()
                        this.setState({...this.state,code:e.target.value},()=>{
                            if(this.state.code.length===10)
                            {
                                this.setState({...this.state,showButton:false},()=>{
                                    this.handleClick(null)
                                })
                            }
                                
                        }
                        )}}
                    />
                    </div>
                    <Collapse isOpen={this.state.showError} >
                    <div style={{color:'white',backgroundColor:'rgba(255,20,20,0.7)'}}>
                        Ce code n'est pas valable
                    </div>
                    </Collapse>
                    <div className="text-center">
                    {this.state.showButton ?
                    <Button className="mx-0 my-1" onClick={(e)=>{this.handleClick(e)}}>
                    Je profite de ma Musique</Button>
                    :
                    <Button disabled className="mx-0 my-1" color="warning" onClick={(e)=>{this.handleClick(e)}}>
                    Verification du code en cours</Button>
                    }
                    </div>
                    <div className="text-center">
                    <a href="#" onClick={()=>{this.handleHelp()}} ><Fa icon="question-circle" /> J'ai besoin d'assistance</a>
                    </div>
                    
            </div>
      );
    }
  };
  
  export default GetCode;

export const getWebsite = ()=>{
    var m = localStorage.getItem('domain')
    var website = 1
    console.log(m)
    switch(m)
    {
      case 'cicbp':
        website = 3
        break;
      case 'cmcicms':
        website = 9
        break
      case 'cmcicbail':
        website = 5
        break
      case 'cmcices':
        website = 7
        break
      case 'cmcicinvest':
        website = 8
        break   
      case 'cmcicconseil':
        website = 6
        break      
      case 'cmcicam':
        website = 4
        break     
      case 'becm':
        website = 2
        break                     
      default:
        website = 1
    }
    return website
}

export const save = (action,payload) => {
    var url = 'https://stream.hubmuzk.com/Stats/' + action 
    var json = JSON.stringify(payload)
    fetch(url,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          method: "POST",
          body: json
    }).then((res)=>{console.log(res.json())})
    .catch((res)=>{console.log(res)})
}

export const getLocalCode = ()=>{
    let code = localStorage.getItem('code')
    return code
}

export const saveStream = (disc,track, duration) => {
    var code = getLocalCode()
    var website=getWebsite()
    var stream={website,code,duration,disc,track}

    console.log(stream)
    save('SaveStream',stream)
    // fetch to webservice

}

export const saveDownload = (disc,mode) => {
    var code = getLocalCode()
    var website=getWebsite()
    var zip=localStorage.getItem('zip')
    var track=0
    var down={website,code,mode,disc,track}
    console.log(down)
    save('SaveDownload',down)
    // fetch to webservice

}

export const saveAction = (action) =>{
    var code = getLocalCode()
    var website=getWebsite()
    var act={website,code,action}
    console.log(act)
    save('SaveAction',act)
}




import * as firebase from 'firebase'

const baseUrl =  'https://us-central1-collectioncic2018.cloudfunctions.net/'

//const baseUrl = ' http://localhost:5001/collectioncic2018/us-central1/'
export const cfetch = (fct,parms)=>{

    var url=baseUrl + fct + '?' + parms
    var headers = new Headers()
    headers.append('Access-Control-Allow-Origin','*')

    return new Promise((resolve,reject)=>{
        fetch(url,{mode: 'cors', headers:headers}).then(
            (res)=>{return resolve(res.json())},
        (reason)=>{
            console.log(reason);
            return reject(reason)
        })
    })
}

export const addSupport = (email,code,infos,message,sender)=>{
    return cfetch('addSupport','code='+code+'&email='+email+'&message='+message+'&infos='+infos+'&sender='+sender) 
}

export const validateCode = (code,userAgent)=>{
    return cfetch('validateCode','challenger='+code+'&ua='+navigator.userAgent) 
}

export const checkCode = (code) => {
    return cfetch('checkCode','challenger='+code)
}

export const getCodes = ()=> {
    return cfetch('getCodes','active=0')
}

export const getActiveCodes = ()=> {
    return cfetch('getCodes','active=1')
}

export const resetCode = (code) => {
    return cfetch('resetCode','challenger='+code)
}

export const getCode = (code) => {
    return cfetch('getCode','code='+code)
}

export const saveCaddy = (name,data) => {
    return cfetch('saveCaddy','name='+name+'&'+'data='+data)
}

export const createCaddy = (name,code) => {
    return cfetch('createCaddy','name='+name+'&'+'code='+code)
}

export const getCaddy = (name,code) => {
    return cfetch('getCaddy','name='+name+'&'+'code='+code)
}

// ------------------
// Firebase real time
//-------------------

const config = {
    apiKey: "AIzaSyC8SHa4frWZrbtV1RW1ce67TcqDcw_Bbdw",
    authDomain: "collectioncic2018.firebaseapp.com",
    databaseURL: "https://collectioncic2018.firebaseio.com",
    projectId: "collectioncic2018",
    storageBucket: "collectioncic2018.appspot.com",
    messagingSenderId: "674311028998"
};

if(!firebase.apps.length) {
    firebase.initializeApp(config)
}

export const listenToRef = (path,callback) => {
    firebase.database().ref(path).on('value',(snap)=>{
        if(snap.exists())
        {
            console.log(snap.val())
            callback(snap.val())
        }
        else {
            console.log('pas encore defini')
        }
    })
}

export const stopListeningToRef = (path) => {
    firebase.database().ref(path).off()
}

export const deleteRef = (path,callback) => {
    firebase.database().ref(path).set(null).then(callback)
}
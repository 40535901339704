import React, {Component} from 'react'
import Navigation from './Navigation.jsx'
import Album from './Album.jsx'
import alb01 from './alb01.js'
import alb02 from './alb02.js'
import alb03 from './alb03.js'
import alb04 from './alb04.js'
import alb05 from './alb05.js'
import alb06 from './alb06.js'
import alb07 from './alb07.js'
import visuel1 from '../Images/Boccherini.png'
import visuel2 from '../Images/Festival Aix.png'
import visuel3 from '../Images/Folle jour.png'
import visuel4 from '../Images/Victoires.png'
import visuel5 from '../Images/Edward Elgar.png'
import visuel6 from '../Images/Brahms.png'
import visuel7 from '../Images/Mendelssohn.png'

//import illustration from '../Home/home.png'
//import Concerts from './Concerts.jsx'

import HelpModal from '../Help/HelpModal'
import { saveAction, saveDownload, saveStream } from '../webservice/index'

import { Fa } from 'mdbreact';


//import { HashLink } from 'react-router-hash-link'

import _ from 'lodash'
import CoolConcerts from './CoolConcerts';
import { isBrowser } from 'react-device-detect';
import platform from 'platform'

//import {listenToRef,stopListeningToRef} from '../firebase/manager'
import { InstalUsage } from './InstalUsage';

class Medias extends Component
{
    constructor(props)
    {
        super(props)

        this.changeSource = this.changeSource.bind(this)
        this.handleFileEnd = this.handleFileEnd.bind(this)
        this.handlePlayerPause = this.handlePlayerPause.bind(this)
        this.handlePlayerPlay = this.handlePlayerPlay.bind(this)
        this.handleSetCurrentTime = this.handleSetCurrentTime.bind(this)
        this.handleAfterInstall = this.handleAfterInstall.bind(this)
        this.handleZipSwitch = this.handleZipSwitch.bind(this)
        this.handleUseDownloader = this.handleUseDownloader.bind(this)

        this.activeAlbum = ""
        this.lastPlayedSrc = ""
        this.playlist = []
        
        var code = localStorage.getItem('code')
        var device = localStorage.getItem('device')
        var downloader = localStorage.getItem('downloader')
        var downloaderinstalled = localStorage.getItem('downloaderinstalled')
        var zip = localStorage.getItem('zip')
        this.state={code,device,downloaderinstalled,downloader,zip,'src' : '', 'progress':0, 'state' : 'none', showInstall:false}

        if(!code)
            window.location.replace("/")

    }

    componentDidMount() {
        this.audio = document.getElementById('audio');
        this.source = document.getElementById('audioSource');

    }

    handleAfterInstall() {
        var code = localStorage.getItem('code')
        var device = localStorage.getItem('device')
        var downloader = localStorage.getItem('downloader')
        var downloaderinstalled = localStorage.getItem('downloaderinstalled')
        var zip = localStorage.getItem('zip')
        this.setState({...this.state,code,device,downloader,downloaderinstalled,zip,'src' : '', 'progress':0, 'state' : 'none', showInstall:false})
    }

    handleZipSwitch() {
        localStorage.setItem('downloader',0)
        localStorage.setItem('zip',1)
        this.setState({downloader:"0",zip:"1"})
        saveAction('basculer zip')
    }

    handleUseDownloader()
    {
        var downloaderinstalled = localStorage.getItem('downloaderinstalled')
        if(downloaderinstalled)
        {
            localStorage.setItem('downloader',1)
            localStorage.setItem('zip',0)
            this.setState({downloader:"1",zip:"0"})
            saveAction('basculer gestionnaire')
        }        
    }

    handlePlaylist(name,playlist)
    {
        var item = {"name": name, "playlist" : playlist}
        this.playlist.push(item)
        //console.log(this.playlist)
    }

    changeSource(name,e)
    {
        var src = e.src
        var action = e.action

        this.activeAlbum=name

        if(action==="pause")
        {
            console.log('doPause')
            this.audio.pause()
            return
        }

        if(action==="play")
        {
            // si la src n'a pas changée on déclanche juste le play
            if(this.state.src !== "" && src === this.state.src)
            {
                // play après pause
                this.audio.play()
            }
            else{

                console.log('change source to : ' + src)
                this.setState({'src' : src, 'progress':0, 'state':'start'},(ps)=>{
                    let dur = this.audio.currentTime
                    
                    //console.log(this.state)
                    var tt=src.split('-')
                    this.source.src='https://stream.hubmuzk.com/stream/aac/' + tt[0] + '/' + tt[1]
                    this.audio.load()
                    this.audio.play()
                    if(this.lastPlayedSrc)
                    {
                        var st=this.lastPlayedSrc.split('-')
                        saveStream(st[0],st[1],dur)
                    }
                    
                    this.lastPlayedSrc = src
                })
            }
        }
    }

    nextTrack = (e) =>
    {
        var src = this.lastPlayedSrc
        console.log(this.activeAlbum)
        var apidx = _.findIndex(this.playlist,(item)=>item.name===this.activeAlbum)
        if(apidx>=0)
        {
            var apl = this.playlist[apidx].playlist
            console.log(apl)
            var ci = _.indexOf(apl,src)
            console.log(src)
            if(ci<apl.length-1)
            {
                console.log(ci)
                var it= {"src": apl[ci+1], "action": "play" }
                this.changeSource(this.activeAlbum,it)
            }
        }
    }

    handleFileEnd(e){
        console.log('player ended')
        this.setState({'src':'', 'progress':0, 'state':'end'},(ps)=>{
            this.nextTrack()
        })
    }

    handlePlayerPause(e){
        const {src,progress} = this.state;
        this.setState({'src':src, 'progress':progress, 'state':'pause'},(ps)=>{
            //console.log(this.state)
        })
    }
    
    handlePlayerPlay(e){
        const {src,progress} = this.state;
        this.setState({'src':src, 'progress':progress, 'state':'play'},(ps)=>{
            //console.log(this.state)
        })
    }

    handleSetCurrentTime(pct)
    {
        if(this.audio.duration && this.audio.duration > 0)
        {
            var newTime=Math.floor(this.audio.duration*pct)
            this.audio.currentTime = newTime
        }
    }

    handlePlayerTimeUpdate(e){
        
        if(this.audio.duration>0 && this.state)
        {
            const {src} = this.state;
            var progress = this.audio.currentTime / this.audio.duration   
            this.setState({'src':src, 'progress':progress, 'state':'play'},(ps)=>{
            })
        }
    }

    handleDownloaderInstall()
    {
        this.setState({...this.state,showInstall:true})

    }
    
    render(){

        var wlh = window.location.href
        var domain = wlh.match(/collection([a-z]+)2018/i)
        var mdom = (domain&&(domain.length>1))?domain[1]:'cic' 
        localStorage.setItem('domain',mdom)
        var becm = mdom=='becm'
        var linkLivret = becm ?
        'https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com/o/LivretBecm.pdf?alt=media&token=a49eb41e-343b-4141-9b45-d0289f38447b'
        :
        'https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com/o/Livret.pdf?alt=media&token=992b01ed-6613-4da3-9a73-11721f027ab8'
        
        
        //'http://graphics.musicme.com/cic/Livret' + ((mdom=='becm')?'Becm.pdf':'.pdf')

        //console.log(linkLivret)
        //console.log(becm)
        var family = platform.os.family
        var isWin=family.includes('indow')
        //console.log(String(platform.os.family))
        //console.log(this.state)

        //console.log({isBrowser,showInstall:this.state.showInstall,downloaderinstalled:this.state.downloaderinstalled ,down:this.state.downloader,zip:this.state.zip })
        //console.log('showInstall Ges',isBrowser && !this.state.showInstall && (!this.state.downloaderinstalled || this.state.downloaderinstalled =="0")  && this.state.zip )

        if(!this.state.code)
            return null
        else
            return (
                <div className="bgWhite">
                
                <div id="home"></div>
                <Navigation onHelpClick={()=>{this.setState({helpVisible:true},()=>{
                    saveAction('show help')
                })}} />
                <div style={{backgroundColor:'#222'}} >
                <CoolConcerts becm={becm} />
                </div> 
                <InstalUsage showInstall={this.state.showInstall} code={this.state.code} device={this.state.device} onAfterInstall={this.handleAfterInstall} />
                <HelpModal page="media" sender={mdom} onClose={() => this.setState({ helpVisible: false })} visible={this.state.helpVisible} />

                <div id="container" className="container">
            
                <div className="mt-5 ">

                <a className="btn btn-primary btn-lg mx-auto"
                onClick={()=>{saveAction('livret')}}
                href={linkLivret} target='_blank' >
                    <Fa icon="download" className="mr-2"/>Télécharger le livret
                </a>
                
                {isBrowser && !this.state.showInstall && !this.state.downloaderinstalled && !this.state.zip &&
                <button className= {"btn btn-primary btn-lg ml-4" } onClick={()=>{this.handleDownloaderInstall()}} >
                    <Fa icon="download" className="mr-2"/>Télécharger les Fichiers
                </button>}
                
                {isBrowser && !this.state.showInstall && this.state.downloaderinstalled =="0" && this.state.zip &&
                <button className= {"btn btn-secondary btn-lg ml-4" } onClick={()=>{this.handleDownloaderInstall()}} >
                    <Fa icon="download" className="mr-2"/>Installer le gestionnaire
                </button>}   

                {isBrowser && !this.state.showInstall && this.state.downloaderinstalled =="1" && this.state.downloader=="0" && this.state.zip &&
                <button className= {"btn btn-secondary btn-lg ml-4" } onClick={()=>{this.handleUseDownloader()}}  >
                    <Fa icon="download" className="mr-2"/>Utiliser le gestionnaire
                </button>}  

                {isBrowser && !this.state.showInstall && this.state.downloader=="1" && this.state.zip=="0" &&
                <button className= {"btn btn-secondary btn-lg ml-4" } onClick={()=>{this.handleZipSwitch()}}  >
                    <Fa icon="archive" className="mr-2"/>Télécharger au format zip
                </button>}                   

                </div>

                <div>
                    <audio id="audio" style={{width: "100%"}} 
                    onEnded={(e)=>this.handleFileEnd(e)}
                    onPause={(e)=>this.handlePlayerPause(e)}
                    onPlay={(e)=>this.handlePlayerPlay(e)}
                    onTimeUpdate={(e)=>this.handlePlayerTimeUpdate(e)}
                    >
                    <source id="audioSource" src=""></source>
                        Your browser does not support the audio format.
                    </audio>
                </div>

                   

                <div >
                <Album id="boccherini" downloader={this.state.downloader} zip={this.state.zip}  title={alb02.title} artists={alb02.artists} disks={alb02.disks} 
                visuel={visuel1} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb02',pl)}} 
                onClick={(src)=>{this.changeSource('alb02',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress} 
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                <div  >
                <Album id="festivalaix" downloader={this.state.downloader} zip={this.state.zip}   title={alb03.title} artists={alb03.artists} disks={alb03.disks} 
                visuel={visuel2} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb03',pl)}}
                onClick={(src)=>{this.changeSource('alb03',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                <div  >
                <Album id="jour" downloader={this.state.downloader} zip={this.state.zip}  title={alb01.title} artists={alb01.artists} disks={alb01.disks} 
                visuel={visuel3} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb01',pl)}}
                onClick={(src)=>{this.changeSource('alb01',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                
                <div >
                <Album id="victoire" downloader={this.state.downloader} zip={this.state.zip}  title={alb04.title} artists={alb04.artists} disks={alb04.disks} 
                visuel={visuel4} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb04',pl)}} 
                onClick={(src)=>{this.changeSource('alb04',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                <div >
                <Album id="edward" downloader={this.state.downloader} zip={this.state.zip}  title={alb05.title} artists={alb05.artists} disks={alb05.disks} 
                visuel={visuel5} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb05',pl)}} 
                onClick={(src)=>{this.changeSource('alb05',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                <div >
                <Album id="brahms" downloader={this.state.downloader} zip={this.state.zip}  title={alb06.title} artists={alb06.artists} disks={alb06.disks} 
                visuel={visuel6} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb06',pl)}} 
                onClick={(src)=>{this.changeSource('alb06',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
                <div >
                <Album id="mendel" downloader={this.state.downloader} zip={this.state.zip}  title={alb07.title} artists={alb07.artists} disks={alb07.disks} 
                visuel={visuel7} broadcastPlaylist={(pl)=>{this.handlePlaylist('alb07',pl)}} 
                onClick={(src)=>{this.changeSource('alb07',src)}} activeTrack={this.state.src} activeState={this.state.state} progress={this.state.progress}
                onSetCurrentTime={(pct)=>this.handleSetCurrentTime(pct)}/>
                </div>
            </div>
            </div>
        )
    }
}

export default Medias
//import illustration from "./home.png"
import React from "react"
import photos from "./photos.png"
import GetCode from "./GetCode"
import { Mask, View, Collapse, Fa } from "mdbreact"
import { checkCode, validateCode } from "../firebase/manager"
import './home.css'

import homecic from '../altdigital/homes/home-cic.jpg'
import homecicbp from '../altdigital/homes/home-banque-privee.jpg'
import homecicms from '../altdigital/homes/home-market-solutions.jpg'
import homecicbail from '../altdigital/homes/home-bail.jpg' 
import homecicinvest from '../altdigital/homes/home-investissement.jpg'
import homecices from '../altdigital/homes/home-epargne-salariale.jpg'
import homecicconseil from '../altdigital/homes/home-conseil.jpg'
import homecicam from '../altdigital/homes/home-asset-management.jpg'
import homebecm from '../altdigital/homes/home-becm.jpg' 


import HelpModal from '../Help/HelpModal'



class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {overlay:'none'} 
    this.handleCodeFailure = this.handleCodeFailure.bind(this)

  }

  handleCodeFailure()
  {
    this.setState({...this.state,overlay:'black-strong'})
  }

  render() {

    console.log(window.location.href)
    var wlh = window.location.href
    var domain = wlh.match(/collection([a-z]+)2018/i)
    var m = (domain&&domain.length>1)?domain[1]:'cic' 
    var illustration = homecic
    console.log(m)
    switch(m)
    {
      case 'cicbp':
        illustration = homecicbp
        break;
      case 'cmcicms':
        illustration = homecicms
        break
      case 'cmcicbail':
        illustration = homecicbail
        break
      case 'cmcices':
        illustration = homecices
        break
      case 'cmcicinvest':
        illustration = homecicinvest
        break   
      case 'cmcicconseil':
        illustration = homecicconseil
        break    
      case 'cmcicinvest':
        illustration = homecicinvest
        break      
      case 'cmcicam':
        illustration = homecicam
        break     
      case 'becm':
        illustration = homebecm
        break                     
      default:
        illustration = homecic
    }
    return (
      <div className="App" style={{backgroundColor:'black'}}>
        <View>
          <img src={illustration} width="100%" alt="" />
          <Mask overlay={this.state.overlay} className="flex-center">
            <GetCode onHelp={()=>this.setState({visible:true})} onCodeFailure={this.handleCodeFailure} />
          </Mask>
        </View>
        <p className="infocode" >Ce code sera automatiquement enregistré sur votre ordinateur ou mobile, vous n’aurez donc à le saisir qu’une fois. 
L’utilisation de votre code est limitée à trois ordinateurs ou mobiles.</p>
        <HelpModal page="home" sender={m} onClose={() => this.setState({ visible: false })} visible={this.state.visible} />
      </div>
      
    )
  }
}

export default Home

import React  from 'react'
//import {Button, Input,Row,Col} from 'mdbreact'

class Download extends React.Component  {

    constructor(props)
    {
        super(props)
        console.log(props)
    }

    render()
    {
        return(
        <div className="App">
            <header className="App-header">
            <h1 className="App-title">Collection CIC 2018</h1>
            </header>
            <a href="cicdownload://download/0102030405/LT1101">telecharger</a>
        </div>
        )
    }0
}

export default Download
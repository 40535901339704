import React from 'react'
import {Fa} from 'mdbreact'
import PropTypes from 'prop-types'

const InstallInstructions = ({onDownloaderClick,onZipClick,downloaderLink})=>{

    // downloaderLink=isWin?pclink:macLink
    // onDownloaderClick=this.handleDownloaderInstall()
    // onZipClick= this.handleZipOptin()

    return (
        <div>
            <p >
            Vous souhaitez télécharger les enregistrements. Ceux-ci sont en qualité CD, et sont donc nécessairement volumineux.
            </p>
            <p>Nous vous conseillons pour cela d’utiliser le gestionnaire de téléchargement 
                (installation guidée très simple en 4 clics) qui permet:</p>
            <ul>
                <li>D’assurer l’intégrité des fichiers reçus en particulier en cas de panne ou variation de courant.</li>
                <li>De ranger automatiquement vos disques dans votre répertoire « Musique ».</li>
                <li>D’éviter les doublons.</li>
            </ul>
            <p>
                En cliquant sur le bouton ci-dessous le téléchargement du gestionnaire démarrera automatiquement
                et l'aide à l'installation s'affichera.
            </p>
            <a className= {"btn btn-primary btn-lg ml-4" } onClick={onDownloaderClick} href={downloaderLink} target='_blank'>
            <Fa icon="download" className="mr-2"/>Installer le Gestionnaire
            </a>
            <p className="mt-3">
            Si vous ne souhaitez pas utiliser le gestionnaire de téléchargement, 
            vous avez la possibilité de télécharger votre musique au format « zip ». 
            Il vous sera alors nécessaire de « dézipper » votre dossier dans votre espace de téléchargement, 
            de le renommer et le ranger dans votre dossier musique.
            </p>
            <a className= {"btn btn-warning btn-lg ml-4" } onClick={onZipClick} href="#" >
            <Fa icon="archive" className="mr-2"/>Je préfère utiliser le format zip
            </a>                   
        </div>
    )
}

InstallInstructions.propTypes = {
    onDownloaderClick : PropTypes.func.isRequired,
    onZipClick : PropTypes.func.isRequired,
    downloaderLink : PropTypes.string.isRequired
}

export default InstallInstructions
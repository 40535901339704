import React, { Component } from 'react'
import InstalUsage from '../Medias/InstalUsage'
import Navigation from '../Medias/Navigation'

export default class Install extends Component {

  render() {
    
    var code=localStorage.getItem('code')
    var device=localStorage.getItem('device')

    return (
      <div className="bgWhite">
        
        <Navigation />
        <h5>Version os X</h5>
        <InstalUsage code={code} device={device} showInstall={true} forceOs={'osx'} />
        <h5>Version Windows</h5>
        <InstalUsage code={code} device={device} showInstall={true} forceOs={'win'} />

      </div>
    )
  }
}

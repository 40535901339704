
//import  { Carousel, CarouselInner, CarouselItem, View, Container , Mask, CarouselCaption,CarouselControl,CarouselIndicators, CarouselIndicator} from 'mdbreact'

import React, {Component} from 'react'
import { HashLink } from 'react-router-hash-link'
import TouchCarousel,{clamp} from 'react-touch-carousel'
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC'
import {isBrowser} from "react-device-detect"

import {concerts} from './concerts.js'
import NonPassiveTouchTarget from '../TouchCarousel/NonPassiveTouchTarget'
import './touch.css'

const cardSize = 300
const cardPadCount = 3
const carouselWidth = clamp(window.innerWidth, 0, 1600)

function CarouselContainer (props) {
  const {cursor, carouselState, ...rest} = props
  // Put current card at center
  const translateX = isBrowser ?
    (cursor - cardPadCount) * (cardSize+25) + (carouselWidth - cardSize-25) / 2
    :
    (cursor - cardPadCount) * (cardSize) + (carouselWidth - cardSize) / 2
  return (
    <NonPassiveTouchTarget className='carousel-container'>
      <NonPassiveTouchTarget
        className='carousel-track'
        style={{transform: `translate3d(${translateX}px, 0, 0)`}}
        {...rest}
      />
    </NonPassiveTouchTarget>
  )
}

const Container = touchWithMouseHOC(CarouselContainer)

class CoolConcerts extends Component
{
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.prev = this.prev.bind(this);
      this.state = {
        activeItem: 1,
        length: 7
      };

      this.renderCard=this.renderCard.bind(this)

    }

    componentDidMount(){
      var carousel = document.getElementById("carousel")
      if(carousel)
      {
        //carousel.classList.remove('carousel-fade')
        //carousel.classList.add('carousel-slide')
      }
    }

    next() {
      let nextItem = this.state.activeItem + 1;
      if(nextItem > this.state.length) {
        this.setState({ activeItem: 1 });
      } else {
        this.setState({ activeItem: nextItem });
      }
    }

    prev() {
      let prevItem = this.state.activeItem - 1;
      if(prevItem < 1) {
        this.setState({ activeItem: this.state.length });
      } else {
        this.setState({ activeItem: prevItem });
      }
    }
    goToIndex(item) {
      if (this.state.activeItem !== item) {
        this.setState({
          activeItem: item
        });
      }
    }

    renderCard(index,modIndex,cursor) {
      const list = concerts(this.props.becm)
      const item = list[modIndex]
      const rotate =  isBrowser?0:40*(index+cursor)
      const onTop = Math.abs(index + cursor) < 0.5

      return (
        <div key={index} className='carousel-card' style={{marginLeft:isBrowser?25:0}}>
          <div
            className='carousel-card-inner'
            style={{
              transform: `rotate(${rotate}deg)`,
              zIndex: onTop ? 1 : 0
            }}
          >
           
           <HashLink style={{color:'white'}} smooth to={'#'+item.id}>
           <img src={item.visuel} height="300" width="300" alt= {item.title} className='image-card' />
           {/* <div className='carousel-title'>{item.title}</div> */}
           </HashLink>
          </div>
        </div>
      )
    }

    render()
    {
        //console.log('props.becm='+this.props.becm)
        var list = concerts(this.props.becm)

        return (
          <TouchCarousel
          component={Container}
          cardSize={cardSize}
          cardCount={list.length}
          cardPadCount={cardPadCount}
          autoplay={4e3}
          renderCard={this.renderCard}
        />
        )
    }
}
export default CoolConcerts
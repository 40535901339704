const alb04 = {
    "name" : "alb04",
    "artists" : "",
    "title" : "Victoires de la Musique Classique",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1106",
            "artists" : ["Eva Zaïcik,  mezzo-soprano","Bruno Philippe, violoncelle","Sélim Mazari, piano"],
            "duration" : "1:25:00",
            "description" : "",
            "sections" : [
                {
                    "name" : "",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Manuel de Falla, Nana (extrait de Siete Canciones populares Españolas, G. 40)",
                            "duration" : "3:48"
                        },
                        {
                            "number" : "2",
                            "title" : "Domenico Scarlatti, Sonate en si mineur, K. 87",
                            "duration" : "5:44"
                        },
                        {
                            "number" : "3",
                            "title" : "Domenico Scarlatti, Sonate en mi majeur, K. 162",
                            "duration" : "5:50"
                        },
                        {
                            "number" : "4",
                            "title" : "Johann Sebastian Bach, Prélude (extrait de la Suite n° 1, BWV. 1007)",
                            "duration" : "2:25"
                        },
                        {
                            "number" : "5",
                            "title" : "Frédéric Chopin, Scherzo en ut dièse mineur n° 3, op. 39",
                            "duration" : "7:06"
                        },
                        {
                            "number" : "6",
                            "title" : "Dimitri Chostakovitch, La Chanson d’Ophélie (extrait d’Hamlet, op. 32)",
                            "duration" : "2:49"
                        },
                        {
                            "number" : "7",
                            "title" : "Piotr Ilitch Tchaïkovski, « Da, vspomnila... Podrugi milie » (extrait de La Dame de pique)",
                            "duration" : "3:15"
                        },
                        {
                            "number" : "8",
                            "title" : "Gustav Mahler, Erinnerung, (extrait de Lieder und Gesänge aus der Jugendzeit)",
                            "duration" : "2:42"
                        },
                        {
                            "number" : "9",
                            "title" : "Gustav Mahler, Oft denk’ ich, sie sind nur ausgegangen! (extrait de Kindertotenlieder)",
                            "duration" : "2:54"
                        },
                        {
                            "number" : "10",
                            "title" : "Gabriel Fauré, Elégie, op. 24",
                            "duration" : "7:06"
                        },
                        {
                            "number" : "11",
                            "title" : "Johannes Brahms, Von ewiger Liebe, op. 43 n° 1 (extrait de Vier gesänge)",
                            "duration" : "4:26"
                        },
                        {
                            "number" : "12",
                            "title" : "Jules Massenet, Méditation de Thaïs",
                            "duration" : "5:28"
                        },
                        {
                            "number" : "13",
                            "title" : "Frédéric Chopin, Introduction et polonaise brillante en ut, op. 3",
                            "duration" : "9:30"
                        },
                        {
                            "number" : "14",
                            "title" : "Jules Massenet, Elégie, op. 10 n° 5",
                            "duration" : "3:05"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb04
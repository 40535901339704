const alb05 = {
    "name" : "alb05",
    "artists" : "",
    "title" : "Edward Elgar",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1107",
            "artists" : ["Ayako Tanaka, violon","Cécile Roubin, violon","Lise Berthaud, alto","Ophélie Gaillard, violoncelle","Claire Désert, piano"],
            "duration" : "1:25:00",
            "compositeur" : "Edward Elgar (1857-1934)",
            "sections" : [
                {
                    "name" : "Quintette avec piano, op. 84",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Moderato. Allegro",
                            "duration" : "13:38"
                        },
                        {
                            "number" : "2",
                            "title" : "Adagio",
                            "duration" : "10:58"
                        },
                        {
                            "number" : "3",
                            "title" : "Andante. Allegro",
                            "duration" : "10:13"
                        }
                    ]
                },
                {
                    "name" : " ",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "4",
                            "title" : "Liebegruss (Salut d'amour), op. 12",
                            "duration" : "3:13"
                        }
                    ]
                },
                {
                    "name" : "Sonatina pour piano en sol majeur",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "5",
                            "title" : "Andantino",
                            "duration" : "1:14"
                        },
                        {
                            "number" : "6",
                            "title" : "Allegro",
                            "duration" : "1:33"
                        }
                    ]
                },
                {
                    "name" : "Chanson de nuit et Chanson de matin, op.15",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "7",
                            "title" : "Chanson de nuit",
                            "duration" : "4:00"
                        },
                        {
                            "number" : "8",
                            "title" : "Chanson de matin",
                            "duration" : "3:03"
                        }
                    ]
                },
                {
                    "name" : " ",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "9",
                            "title" : "Sospiri, op. 70",
                            "duration" : "3:54"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb05
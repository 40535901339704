import React from 'react'
import PropTypes from 'prop-types'
import down1 from '../Images/scdownload1.png'
import down2 from '../Images/scdownload2.png'
import down3 from '../Images/scdownload3.png'
import {Button, Fa , Row, Col} from 'mdbreact';

const InstallMac = ({showWaitingDownloader,onClickValidate,code,device}) => {
    return (
        <div>
                <h3 className="textCenter mb-5 mt-0 pt-5">Installation du gestionnaire Mac</h3>
                <p>Le téléchargement du gestionnaire est en cours. Vous pouvez suivre sa progression dans votre naviguateur.</p>
                <Row>
                    <Col md="2">
                    <h1>1.</h1>
                    </Col><Col md="8">
                        <img src={down1} width="200" alt=""/>
                        <p>Lorsque le téléchargement sera terminé, cliquez sur l'icône du fichier téléchargé.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                    <h1>2.</h1>
                    </Col>
                    <Col md="8">
                        <img src={down2} width="200" alt=""/>
                        <p>Glissez ensuite l'icône du gestionnaire vers le dossier Applications.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                    <h1>3.</h1>
                    </Col><Col md="8">
                        <Button className="btn-warning mt-1"  disabled={showWaitingDownloader} onClick={onClickValidate} tag="a" color='warning' href={"coldownload://validate/" + code + "/" + device}>
                            Valider l'installation.                      
                        </Button>
                        <p>Cliquez sur ce bouton pour valider l'installation, la petite fenêtre représentée à l'étape suivante devrait s'ouvrir.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                    <h1>4.</h1>
                    </Col>
                    <Col md="8">
                        <img src={down3} width="300" alt=""/>
                        <p>Cliquez sur ouvrir pour autoriser le gestionnaire à s'ouvrir.</p>
                    </Col>
                </Row>                        
                {showWaitingDownloader &&
                <Row>
                    <h1>Détection de l'ouverture du gestionnaire ...</h1>
                </Row>}
        </div>
    )
}

InstallMac.propTypes = {
    showWaitingDownloader : PropTypes.bool.isRequired,
    onClickValidate: PropTypes.func.isRequired,
    code : PropTypes.string.isRequired,
    device : PropTypes.string.isRequired
}

export default InstallMac;

import React from "react"
import { Mask, View, Collapse, Fa } from "mdbreact"
import { checkCode, validateCode } from "../firebase/manager"

import HelpModal from './HelpModal'

class Help extends React.Component {
  constructor(props) {
    super(props)
    // var code=localStorage.getItem('code')
    // if(code)
    // {
    //     checkCode(code).then((u)=>{
    //         if(u.access && u.access>=0)
    //             window.location.replace("/Media/"+code)
    //         else
    //             this.setState({...this.state,showButton:true})
    //     })
    // }
    this.state = {overlay:'none',visible:false} 
    this.handleCodeFailure = this.handleCodeFailure.bind(this)
  }

  handleCodeFailure()
  {
    this.setState({...this.state,overlay:'black-strong'})
  }

  render() {
    return (
      <div className="App" style={{backgroundColor:'black'}}>
        <a className="help" href="#" onClick={()=>{this.setState({...this.state,visible:true})}} ><Fa icon="question-circle" /> J'ai besoin d'assistance</a>

        <HelpModal onClose={() => this.setState({ visible: false })} visible={this.state.visible} />

      </div>
      
    )
  }
}

export default Help

const alb02 = {
    "name" : "alb02",
    "artists" : "",
    "title" : "Luigi Boccherini",
    "pitch" : "",
    "disks" : [
        {
            "name" : "CD1",
            "media" : "LT1101",
            "artists" : ["Ophélie Gaillard, violoncelle","Pulcinella Orchestra"],
            "duration" : "1:25:00",
            "compositeur" : "Luigi Boccherini (1743-1805)",
            "sections" : [
                {
                    "name" : "Concerto pour violoncelle n° 6 en ré majeur, G. 479",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "1",
                            "title" : "Allegro",
                            "duration" : "7:05"
                        },
                        {
                            "number" : "2",
                            "title" : "Adagio",
                            "duration" : "4:38"
                        },
                        {
                            "number" : "3",
                            "title" : "Allegro",
                            "duration" : "6:15"
                        }
                    ]
                },
                {
                    "name" : "Symphonie en ré mineur \"La casa del diavolo\", G. 506",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "4",
                            "title" : "Andante sostenuto - Allegro assai",
                            "duration" : "5:35"
                        },
                        {
                            "number" : "5",
                            "title" : "Andantino con moto",
                            "duration" : "5:32"
                        },
                        {
                            "number" : "6",
                            "title" : "Andante sostenuto - Allegro con molto",
                            "duration" : "7:29"
                        }
                    ]
                },
                {
                    "name" : "Concerto pour violoncelle n° 9 sib majeur, G. 482",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "7",
                            "title" : "Allegro moderato",
                            "duration" : "8:03"
                        },
                        {
                            "number" : "8",
                            "title" : "Andantino grazioso",
                            "duration" : "3:59"
                        },
                        {
                            "number" : "9",
                            "title" : "Rondo. Allegro",
                            "duration" : "5:00"
                        }
                    ]
                },
                {
                    "name" : "Quintette à cordes en ut majeur \"La Musica Notturna delle Strade di Madrid\", op. 30 n° 6 (G. 324)",
                    "duration" : "",
                    "tracks" : [
                        {
                            "number" : "10",
                            "title" : "Ave Maria delle Parrochie",
                            "duration" : "0:45"
                        },
                        {
                            "number" : "11",
                            "title" : "Il tamburo dei Soldati",
                            "duration" : "0:43"
                        },
                        {
                            "number" : "12",
                            "title" : "Minuetto dei ciechi",
                            "duration" : "1:59"
                        },
                        {
                            "number" : "13",
                            "title" : "Largo assai. Rosario",
                            "duration" : "5:42"
                        },
                        {
                            "number" : "14",
                            "title" : "Allegro vivo. Los Manolos (Passa calle)",
                            "duration" : "1:57"
                        },
                        {
                            "number" : "15",
                            "title" : "Il tamburo",
                            "duration" : "0:24"
                        },
                        {
                            "number" : "16",
                            "title" : "Ritirata can variazioni. Tempo di Marcia",
                            "duration" : "2:15"
                        }
                    ]
                },
                {
                    "name" : "Sonate pour violoncelle et pianoforte n° 2 en ut mineur, G. 2 (Franscesco Corti, pianoforte)",
                    "duration" : "33:15",
                    "tracks" : [
                        {
                            "number" : "17",
                            "title" : "Adagio",
                            "duration" : "3:38"
                        },
                        {
                            "number" : "18",
                            "title" : "Allegro",
                            "duration" : "4:17"
                        },
                        {
                            "number" : "19",
                            "title" : "Allegretto",
                            "duration" : "3:08"
                        }
                    ]
                }
            ]
        }
    ]
}

export default alb02
import React, { Component } from 'react'
import platform from 'platform'

import {Fa , Row, Col, Container ,Collapse} from 'mdbreact';
import Button from '../Components/Button.jsx'
import {listenToRef,stopListeningToRef} from '../firebase/manager'
import InstallationInstructions from './InstallInstructions'
import InstallMac from './installMac'
import InstallWin from './installWin'

import './instalUsage.css'
import { saveAction } from '../webservice/index.jsx';

export class InstalUsage extends Component {
  
  constructor(props) {
    super(props)
    var family = platform.os.family
    var isWin=family.includes('indow')
    if(props.forceOs)
    {
        isWin=(props.forceOs==='win')
    }
    this.handleDownloaderInstall = this.handleDownloaderInstall.bind(this)
    this.handleZipOptin = this.handleZipOptin.bind(this)
    this.onAfterInstall = this.onAfterInstall.bind(this)

    this.state = {
       showPreInstall:true,
       installation:false,
       showWaitingDownloader:false,
       showInstall:props.showInstall,
       showPostInstall:false,
       code:props.code,
       device:props.device,
       isWin
    }
    //this.showWaitingDownloader()

  }
  
  onAfterInstall()
  {
      if(this.props.onAfterInstall)
        this.props.onAfterInstall()
  }

  handleDownloaderInstall()
  {
    this.setState({...this.state,showPreInstall:false,installation:true})
    var path = 'actives/'+this.state.code+'/devices/'+this.state.device+'/downloader'
    console.log(path);
    listenToRef(path,()=>{
            localStorage.setItem('downloader',1)
            localStorage.setItem('downloaderinstalled',1)            
            localStorage.setItem('zip',0)
            saveAction('telechargement gestionnaire')
            stopListeningToRef(path)
            this.setState({...this.state,showInstall:false,downloader:true})
            this.onAfterInstall()
            })
  }

  handleZipOptin()
  {
        this.setState({...this.state,showPreInstall:false,installation:false})
        localStorage.setItem('zip',1)
        localStorage.setItem('downloader',0)
        localStorage.setItem('downloaderinstalled',0)
        saveAction('choix du zip')
        this.onAfterInstall()
  }

  componentWillReceiveProps(props)
  {
      this.setState({...this.state,showInstall:props.showInstall})
  }

  render() {
    var pclink = 'https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com' + 
    '/o/Telechargement%20Collections%20Setup%202.6.6.exe?alt=media&token=948dcf04-acad-494d-a819-6fa60337b252'
    var macLink ='https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com'+
    '/o/Telechargement%20Collections-2.6.6.dmg?alt=media&token=3671164e-94ae-47f4-be93-18c277804c83'
    //https://firebasestorage.googleapis.com/v0/b/collectioncic2018.appspot.com/
    var family = platform.os.family
    var isWin=family.includes('indow')

    return (
      <div >
        {!this.state.isWin?
        
        <Collapse className="pb-4" style={{backgroundColor:'#f9f8eb',marginBottom:20}} isOpen={this.state.showInstall}>
            <Container>
                <Collapse className="instructions" isOpen={this.state.showPreInstall}>
                    <InstallationInstructions onDownloaderClick={this.handleDownloaderInstall} onZipClick={this.handleZipOptin} downloaderLink={isWin?pclink:macLink} />
                 </Collapse>
                <Collapse isOpen={this.state.installation}>
                    <InstallMac showWaitingDownloader={this.state.showWaitingDownloader} code={this.state.code} 
                    device={this.state.device} onClickValidate={()=>{this.setState({showWaitingDownloader:true})}} />
                </Collapse>
            </Container>
        </Collapse>
:
        <Collapse className="pb-4" style={{backgroundColor:'#f9f8eb',marginBottom:20}} isOpen={this.state.showInstall}>
            <Container>
                <Collapse className="instructions" isOpen={this.state.showPreInstall}>
                    <InstallationInstructions onDownloaderClick={this.handleDownloaderInstall} onZipClick={this.handleZipOptin} downloaderLink={isWin?pclink:macLink} />
                </Collapse>
                <Collapse isOpen={this.state.installation}>
                <InstallWin showWaitingDownloader={this.state.showWaitingDownloader} code={this.state.code} 
                device={this.state.device} onClickValidate={()=>{this.setState({showWaitingDownloader:true},()=>{
                    saveAction('validation gestionnaire')
                })}} />
                </Collapse>
            </Container>
        </Collapse>
        } 
        <Collapse style={{backgroundColor:'#f9f8eb',marginBottom:20}}  isOpen={this.state.showPostInstall}>
            <h1>Votre Gestionnaire est bien installé.</h1>
            <p>Vous pouvez maintenant cliquer sur les boutons ci-dessous vous permettant de télécharger.</p>
        </Collapse>
      </div>
    )
  }
}

export default InstalUsage

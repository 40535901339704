import { Switch, Route } from 'react-router-dom'
import Home from './Home/index'
import React, { Component } from 'react'
import Medias from './Medias/'
import Admin from './Admin/'
import Download from './Download/'
import Install from './Admin/Install.jsx'
import Help from './Help/'

class Main extends Component {
  
  constructor(props)
  {
    super(props)
    // on teste la version
    var currentversion='3'
    var version = localStorage.getItem('version')
    var code = localStorage.getItem('code')
    if(code && (!version || version!=currentversion))
    {
      localStorage.clear()
      localStorage.setItem('code',code)
      localStorage.setItem('version',currentversion)
    }
    // on récupère l'id du device
    var device = localStorage.getItem('device')
    if(!device)
    { // génère un id
      localStorage.setItem('device',Math.random().toString(36).substr(2, 9))
    }
    localStorage.setItem('lastaccess',Date.now())
    
  }
 
  render() {

    const code = localStorage.getItem('code')
    
    return (
  <main>
    <Switch>
      <Route exact path='/' component={code?Medias:Home}/>
      {code && <Route path='/media' component={Medias}/> }
      <Route exact path='/admin/:token' component={Admin}/>
      <Route exact path='/download/' component={Download}/>
      <Route exact path='/install/' component={Install}/>
      <Route exact path='/help/' component={Help}/>
      <Route exact path='*' component={Home}/>
    </Switch>
  </main>
)
  }
}

export default Main
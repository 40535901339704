import React, { Component }  from 'react'

import { Button, Fa, Row, Col } from 'mdbreact';


import Section from './Section.jsx'
import './Album.css'
import { HashLink } from 'react-router-hash-link';
import {isBrowser} from "react-device-detect"
import {deleteRef,listenToRef,stopListeningToRef} from '../firebase/manager'
//import { stringify } from 'querystring';
import platform from 'platform'

import {saveDownload,saveAction} from '../webservice/index'

class Album extends Component {

    constructor(props)
    {
        super(props)
        this.albname=props.albname
        this.title=props.title
        this.disks=props.disks
        this.visuel=props.visuel
        //this.onClick = this.onClick.bind(this)
        this.broadcastPlaylist = this.broadcastPlaylist.bind(this)
        this.activeTrack = props.activeTrack
        this.activeState = props.activeState

        this.playlist = []

        //console.log("build playlist")
        this.disks.map((disk,i)=>{
            disk.sections.map((section,j)=>{
                section.tracks.map((track,k)=>{
                    var trackName = disk.media + '-' + track.number 
                    this.playlist.push(trackName)
                })
            })
        })
        this.code=localStorage.getItem('code')
        this.device=localStorage.getItem('device')
        this.state = {downloader:props.downloader,zip:props.zip,downloading:null}
    }

    broadcastPlaylist(pl) {
        if(this.props.broadcastPlaylist)
        {
            this.props.broadcastPlaylist(pl)
        }
    }

    onSetCurrentTime(pct)
    {
        if(this.props.onSetCurrentTime)
            this.props.onSetCurrentTime(pct)
    }

    onPlay(src) {
        console.log(src)
        //e.preventDefault();
        if(this.props.onClick){
            var action="play"
            if(src===this.props.activeTrack && this.props.activeState==="play")
            {
                action="pause"
            }
            this.props.onClick({src: src, action: action})
        }
    }

    handleWatchDownload(caddyId)
    {
        var path='actives/'+this.code+'/devices/'+this.device+'/'+caddyId
        deleteRef(path,()=>{
            listenToRef(path,(data)=>{
                this.setState({...this.state,downloading:{...data,caddy:caddyId}},()=>{
                    if(this.state.downloading && (this.state.downloading.waiting + this.state.downloading.current)===0)
                    {
                        var downloaded = JSON.parse(localStorage.getItem('downloaded'))||[]
                        downloaded.push({caddyId,dt:Date.now()})
                        localStorage.setItem('downloaded',JSON.stringify(downloaded))
                        stopListeningToRef(path)
                        this.setState({...this.state,downloading:null})
                    }
                })
            })
        })
        
    }

    componentDidMount()
    {
        this.broadcastPlaylist(this.playlist)
    }

    componentWillReceiveProps(newProps)
    {
        if(this.props.downloader!==newProps.downloader)
        this.setState({...this.state,downloader:newProps.downloader,zip:newProps.zip})
    }

    render()
    {
        var downloaded = JSON.parse(localStorage.getItem('downloaded'))
        var wdone = this.state.downloading?this.state.downloading.done:33
        var wcurrent = this.state.downloading?this.state.downloading.current:33
        var waiting = this.state.downloading?this.state.downloading.waiting:33
        var sum=wdone+wcurrent+waiting
        var family = platform.os.family
        var isWin=family.includes('indow')
        var wlh = window.location.href
        var domain = wlh.match(/collection([a-z]+)2018/i)
        var mdom = (domain&&(domain.length>1))?domain[1]:'cic' 
        var becm = mdom=='becm'
        //var becm = true

        //console.log("zip: " + this.state.zip)

        return (
            <div className="row mt-5">
                <div className="col-12">
                <div className="row">
                   
                    <div id={this.props.id} className="col-11">
                    
                    <h3>
                        {this.title}
                    </h3>

                    </div>
                    <div className="col-1">
                    <HashLink to="#home">
                        <Fa icon="angle-double-up" size="2x" className="blue-text px-0 py-0 mr-0 my-0"/>
                    </HashLink>
                    </div>
                </div>
                    <div className="ml-0">
                    </div>
                    {   
                        // on boucle sur les disques
                        this.disks.map((disk,i)=>{
                            var alreadyDown = downloaded && downloaded.filter(e=>e.caddyId===disk.media)[0]
                            
                            return (
                            <div key={'d'+i} className="mt-2">
                                <div className="row">
                                    
                                    <div className="col-10">
                                        <strong>{disk.description}</strong>
                                    </div>
                                    
                                    <div className="col-1"></div>
                                </div>
                                {
                                    disk.artists.map((artiste,a)=>(
                                        <div key={'a'+a} >{artiste}</div>
                                    ))     
                                }
                                <Row>

                                        <div className="listen butalbum ripple " onClick={(e)=>{
                                            console.log('click button play Album')
                                            this.onPlay(disk.media + '-1')
                                            saveAction('lecture album ' + disk.media)
                                            }} >
                                                <Fa icon="play" className="mr-2"/>Ecouter l'album
                                        </div>

                                        {isBrowser && (this.state.downloader==1) &&
                                            <a className="listen butalbum ripple " onClick={()=>{
                                                this.handleWatchDownload(disk.media)
                                                saveDownload(disk.media,0)
                                            }} href={"coldownload://download/" + this.code + "/" +  disk.media + (becm?'?becm=1':'')}    >
                                                <Fa icon="download" className="mr-2"/>Télécharger l'album
                                                
                                            </a>
                                            
                                        }
                                        {isBrowser && (this.state.zip==1) &&
                                            <a className="listen butalbum ripple " onClick={()=>{
                                                saveDownload(disk.media,1)
                                            }}
                                                 href={"https://stream.hubmuzk.com/zip/"+ (isWin?"wma":"alac") + "/" + (becm?'becm-':'') + disk.media}    >
                                                <Fa icon="archive" className="mr-2"/>Télécharger l'album au format zip
                                            </a>
                                            
                                        }
                                    {!this.state.downloading &&
                                    <div className="" style={{width:350,display:'block',verticalAlign:'middle'}}>
                                    {alreadyDown && (alreadyDown.caddyId===disk.media) && <span style={{lineHeight:'55px',fontSize:'0.8em'}} >Téléchargé le {new Date(alreadyDown.dt).toLocaleString()}</span>}
                                    </div>
                                    }
                                    { this.state.downloading && (disk.media===this.state.downloading.caddy) &&
                                        <div className="" style={{width:350,display:'block'}}>
                                            <div className="my-3 z-depth-1-half dwnprogress" style={{backgroundColor:'#d2e1c8',width:(wdone/sum)*100 + '%'}}>{this.state.downloading.done} </div>
                                            <div className="my-3 z-depth-1-half dwnprogress" style={{backgroundColor:'#fee4a6',width: (wcurrent/sum)*100 + '%'}}>{this.state.downloading.current}</div>
                                            <div className="my-3 dwnprogress" style={{backgroundColor:'#f7f4e3',width: (waiting/sum)*100 + '%'}} >{this.state.downloading.waiting}</div>
                                        </div>
                                    }

                                </Row>
                                
                                {disk.compositeur && <div className="">
                                        <strong>{disk.compositeur}</strong>
                                    </div>}
                                {
                                    // on boucle sur les sections
                                    disk.sections.map((section,j)=>(
                                        <div className="mt-5 mb-0" key={'s'+j}>
                                            <Section section={section}/>
                                            {
                                                // on boucle sur les tracks
                                                section.tracks.map((track,k)=>{
                                                        var showProgress=false
                                                        var trackName = disk.media + '-' + track.number 
                                                        var bcolor = "#4285f4"
                                                        var icon = "play"                                               
                                                        if(trackName===this.props.activeTrack)
                                                        {
                                                            showProgress=true
                                                            bcolor="#66aacc"
                                                            if(this.props.activeState==="play")
                                                            {
                                                                icon = "pause"
                                                            }
                                                        }

                                                        return(
                                                        <div key={'t' + k} className="row mt-3">
                                                            <div className="col-1 mt-2">{track.number}.</div>

                                                            <div id={'d'+k} className="col-8 mt-2" onClick={(e)=>{
                                                                var container = document.getElementById('container')
                                                                var div = document.getElementById('d'+k)
                                                                var pct = (e.clientX - (div.offsetLeft + container.offsetLeft + container.clientLeft)) / div.clientWidth
                                                                console.log(pct)
                                                                this.onSetCurrentTime(pct)
                                                            }} >{track.title}
                                                            {showProgress? <div><progress className='mdb-progress animated mb-0' value={this.props.progress}></progress></div>
                                                                : "" }  
                                                            </div>
                                                            <div className="listen ripple col-2" onClick={(e)=>{
                                                                console.log('click on play' + {bcolor})
                                                                this.onPlay(trackName)}} style={{backgroundColor:bcolor,width:'60px'}} >

                                                                    <Fa icon={icon} className="px-0 py-0 mx-2"/>
                                                                    {track.duration}
                                                            </div>
                                                            <div className="col-1"></div>
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    ))
                                }
                                {         
                                    //console.log(this.playlist)
                                }
                            </div>
                        )})
                    }
                    <p>
                    </p>
                </div>
            </div>
        )
    }

}

export default Album

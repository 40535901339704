import React, { Component } from 'react'

export default class Button extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            href:props.href,
            cn : props.className
            
            }
    }

  render() {
    console.log(this.state)
    return (
      <a href={this.state.href} className={"btn " + this.state.cn} >
        {this.props.children}
      </a>
    )
  }
}
